import React from 'react'
import Layout from '../layout';
import Star from "../../../styles/assets/star.svg";
import Logo from "../../../styles/assets/Logo.png"
const Joberr = () => {
  return (
    <>
    <div className='border border-black border-solid m-10 mt-28 rounded-3xl flex justify-between ab:flex-col'>
        <div className='p-5 text-sm'>
            <img src={Logo} alt='companyLogo' className='w-10 h-16' />
            <h2 className='font-semibold mt-4 text-base'>კომპანია <span>ჰამქარი</span> ეძებს:</h2>
            <h2 className='font-light mt-4'>პროექტის მენეჯერს</h2>
            <h2 className='font-light mt-4'>შესასრულებელი სამუშაო - გუნდის დავალებების გაწერა და კონტროლი.</h2>
            <h2 className='font-light mt-4'>კატეგორია: სასწრაფო</h2>
        </div>
        <div className="mt-3 flex justify-center items-start cd:mr-12 mr-4 ab:hidden cd:hidden">
            <img className="mr-1" alt="star" src={Star} />
            <img className="mr-1" alt="star" src={Star} />
            <img className="mr-1" alt="star" src={Star} />
            <img className="mr-1" alt="star" src={Star} />
            <img className="mr-1" alt="star" src={Star} />
            </div>
    </div>
    </>
  )
}

const Jober = () => {
    return <Layout children={<Joberr />} />;
  };
export default Jober
import React, { Fragment, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { ProductDetailsContext } from "./index";
import { LayoutContext } from "../layout";
// import Submenu from "./Submenu";
import ProductDetailsSectionTwo from "./ProductDetailsSectionTwo";
import { getSingleProduct } from "./FetchApi";
import { cartListProduct } from "../partials/FetchApi";
// import { isWishReq, unWishReq, isWish } from "../home/Mixins";
// import { updateQuantity, slideImage, addToCart, cartList } from "./Mixins";
import {  cartList } from "./Mixins";
import Line from "../../../styles/assets/Line.png"
// import { totalCost } from "../partials/Mixins";
import Star from "../../../styles/assets/star.svg";
import { FaTwitter, FaLinkedin, FaFacebook, FaDribbble } from 'react-icons/fa';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const ProductDetailsSection = (props) => {
const [follow, setFollow] = useState(false);
  let { id } = useParams();
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  const { data, dispatch } = useContext(ProductDetailsContext);
  const { data: layoutData, dispatch: layoutDispatch } =
    useContext(LayoutContext); // Layout Context

  const sProduct = layoutData.singleProductDetail;
  const [pImages, setPimages] = useState(null);
  const [count, setCount] = useState(0); // Slide change state

  const [quantitiy, setQuantitiy] = useState(1); // Increse and decrese quantity state
  const [, setAlertq] = useState(false); // Alert when quantity greater than stock

  const [wList, setWlist] = useState(
    JSON.parse(localStorage.getItem("wishList"))
  ); // Wishlist State Control

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    dispatch({ type: "loading", payload: true });
    try {
      let responseData = await getSingleProduct(id);
      setTimeout(() => {
        if (responseData.Product) {
          layoutDispatch({
            type: "singleProductDetail",
            payload: responseData.Product,
          }); // Dispatch in layout context
          setPimages(responseData.Product.pImages);
          dispatch({ type: "loading", payload: false });
          layoutDispatch({ type: "inCart", payload: cartList() }); // This function change cart in cart state
        }
        if (responseData.error) {
          console.log(responseData.error);
        }
      }, 500);
    } catch (error) {
      console.log(error);
    }
    fetchCartProduct(); // Updating cart total
  };

  const fetchCartProduct = async () => {
    try {
      let responseData = await cartListProduct();
      if (responseData && responseData.Products) {
        layoutDispatch({ type: "cartProduct", payload: responseData.Products }); // Layout context Cartproduct fetch and dispatch
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (data.loading) {
    return (
      <div className="col-span-2 md:col-span-3 lg:col-span-4 flex items-center justify-center h-screen">
        <svg
          className="w-12 h-12 animate-spin text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          ></path>
        </svg>
      </div>
    );
  } else if (!sProduct) {
    return <div>ცარიელია</div>;
  }
  return (
    <Fragment>
    <div className="bg-gray-100 min-h-screen">
      <header className="bg-center bg-no-repeat bg-cover h-[calc(100px_+_15vw)]" style={{backgroundImage: 'url(../img/bg.jpeg)'}}></header>

      <div className="max-w-7xl mx-auto p-4 md:p-8 md:grid md:grid-cols-12 gap-12">
        <div className="md:col-span-5 lg:col-span-4">
          <div className="relative mx-auto max-w-xs -mt-32 cd:-mt-20">
            <img className="w-48 h-48 object-cover object-center rounded-full border-4 border-white shadow-lg" src={sProduct.pImages[0]} />
  
          </div>

          <h2 className="mt-12 font-semibold text-xl text-gray-800">{sProduct.pName}</h2>
          <p className="text-gray-500">{sProduct.pCreatorName}</p>

          <ul className="mt-6 flex flex-col justify-between text-gray-500 text-sm">
          <p>{sProduct.pDescription}</p>
          <p>{sProduct.pTele}</p>
          </ul>

          <div className="mt-6">
            <p className="text-gray-700 text-sm">
            {sProduct.pCreatorDescription}
            </p>

            <div className="mt-4 flex"> 
            <FaTwitter className="mr-4 text-xl" />
            <FaLinkedin className="mr-4 text-xl" />
            <FaFacebook className="mr-4 text-xl" />
            
            </div>
          </div>
        </div>

        <div className="md:col-span-7 lg:col-span-8">
          <div className="flex flex-col md:flex-row md:items-center justify-between">
          <button 
              className="mt-6 md:mt-0 bg-blue-500 text-white rounded px-6 py-2 hover:bg-blue-600"
              onClick={() => setFollow(!follow)}>
              {follow ? 'დაურეკე' : 'დაურეკე'} 
            </button>
            <nav>
              <ul className="flex mt-6 md:mt-0">
                <li>პროექტები :</li>  
                {/* <li className="ml-6"><a href="">Galleries</a></li>
                <li className="ml-6"><a href="">Groups</a></li>
                <li className="ml-6"><a href="">About</a></li> */}
              </ul>
            </nav>

            
          </div>
          
        <section className="">
        <div className="mt-8 grid grid-cols-4 gap-4 auto-rows-fr cd:grid-cols-2">
          {sProduct.pImages.slice(1).map((item, index) => (
            <div key={index} onClick={() => openLightbox(index)}>
              <img
                alt="Product"
                src={item}
                className="w-48 h-48 object-cover		"
              />
            </div>
          ))}
        </div>
        
      </section>
      <Lightbox
        open={lightboxOpen}
        close={closeLightbox}
        slides={sProduct.pImages.map((item) => ({
          src: item,
          alt: item.alt,
          width: item.width,
          height: item.height,
          srcSet: item.srcSet,
        }))}
        currentIndex={currentImageIndex}
      />
      
      
        </div> 
      </div>
    </div>
    <ProductDetailsSectionTwo />
    </Fragment>
  );
}
export default ProductDetailsSection;
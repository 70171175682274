import React, { Fragment, useContext, useState, useEffect } from "react";
import Layout from "../layout";
import { useHistory, useLocation } from "react-router-dom";

const Formm = () => {
    const history = useHistory();

  return (
    <div className="flex flex-col min-h-screen items-center justify-center bg-gray-300">
      
      <div className="flex flex-row  cd:flex-col ab:mt-14 justify-between items-center  md:flex-wrap md:justify-center">
        
        <div className="w-[500px] cd:w-2/3 h-80 ab:h-56  flex flex-col justify-between items-center p-8 cd:mr-0 hh:mr-0 mr-10 bg-white rounded shadow-md">
            <div className="flex flex-col items-center">
          <h2 className="text-2xl font-bold text-gray-800 mb-7">ჰამქარი</h2>
          <p className="text-[#AFAFAF] text-center">დარეგისტრირდი ჰამქრად და დაემატე კატალოგში.</p></div>
          <div>
          <button 
          onClick={(e) => history.push("/user/form1")}
          className=" bottom-8 bg-[#ED2F5B]  text-white font-bold py-2 px-4 rounded shadow-md ">
        შეავსე ფორმა
      </button>
      </div>
        </div>

        
        <div className="w-[500px] cd:w-2/3 h-80 ab:h-56 flex cd:mt-5 hh:mt-5 flex-col justify-between items-center p-8 bg-white rounded shadow-md">
        <div className="flex flex-col items-center">
          <h2 className="text-2xl font-bold text-gray-800 mb-7">დამსაქმებელი</h2>
          <p className="text-[#AFAFAF] text-center">შეავსე ფორმა და დაამატე ვაკანსია</p></div>
          <div>
          <button 
          onClick={(e) => history.push("/user/form2")}
          className=" bottom-8 bg-[#ED2F5B] text-white font-bold py-2 px-4 rounded shadow-md ">
          შეავსე ფორმა
      </button>
      </div>
        </div>
      </div>

      <button
       onClick={(e) => history.push("/user/profile")}
       className="  bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded shadow-md mt-10">
        ჩემი პროფილი
      </button>
    </div>
  )
}

const Form = () => {
    return <Layout children={<Formm />} />;
  };
export default Form
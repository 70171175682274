import React, { Fragment, useState, useRef } from "react";
import { signupReq } from "./fetchApi";
import Privacy from "./Privacy"
const Signup = (props) => {
  function Lowercase(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
  }
  const myDivRef = useRef(null);

  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
    cPassword: "",
    error: false,
    loading: false,
    success: false,
  });

  const alert = (msg, type) => (
    <div className={`text-sm text-${type}-500`}>{msg}</div>
  );
  const successes = (msg, type) => (
    <div className={`text-base text-gray-900 p-2 bg-${type}-300`}>{msg}</div>
  );

  const formSubmit = async () => {
    setData({ ...data, loading: true });

    if (data.cPassword !== data.password) {
      return setData({
        ...data,
        error: {
          cPassword: "Password doesn't match",
          password: "Password doesn't match",
        },
      });
    }
    try {
      let responseData = await signupReq({
        name: data.name,
        email: data.email,
        password: data.password,
        cPassword: data.cPassword,
      });
      if (responseData.error) {
        setData({
          ...data,
          loading: false,
          error: responseData.error,
          // password: "",
          // cPassword: "",
        });
      } else if (responseData.success) {
        myDivRef.current.scrollIntoView({ behavior: "smooth", block: "start" });

        setData({
          success: responseData.success,
          name: "",
          email: "",
          password: "",
          cPassword: "",
          loading: false,
          error: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <div
        ref={myDivRef}
        id="myDiv"
        className="text-center text-2xl font-medium mb-6"
      >
        გაიარე რეგისტრაცია
      </div>
      <form className="space-y-4">
        {data.success ? successes(data.success, "green") : ""}
        <div className="flex flex-col">
          <label htmlFor="name" className="text-sm text-gray-600">
            სახელი-გვარი<span className="ml-1">*</span>
          </label>
          <input
            onChange={(e) =>
              setData({
                ...data,
                success: false,
                error: {},
                name: e.target.value,
              })
            }
            value={data.name}
            type="text"
            id="name"
            className={`${
              data.error.name ? "border-red-500" : ""
            } px-4 py-2 focus:outline-none border rounded-md`}
          />
          {!data.error ? "" : alert(data.error.name, "red")}
        </div>
        <div className="flex flex-col">
          <label htmlFor="email" className="text-sm text-gray-600">
            მეილი<span className="ml-1">*</span>
          </label>
          <input
            onChange={(e) =>
              setData({
                ...data,
                success: false,
                error: {},
                email: e.target.value,
              })
            }
            autoComplete="Email"
            value={Lowercase(data.email)}
            type="email"
            id="email"
            className={`${
              data.error.email ? "border-red-500" : ""
            } px-4 py-2 focus:outline-none border rounded-md`}
          />
          {!data.error ? "" : alert(data.error.email, "red")}
        </div>
        <div className="flex flex-col">
          <label htmlFor="password" className="text-sm text-gray-600">
            პაროლი<span className="ml-1">*</span>
          </label>
          <input
            onChange={(e) =>
              setData({
                ...data,
                success: false,
                error: {},
                password: e.target.value,
              })
            }
            autoComplete="new-password"
            value={data.password}
            type="password"
            id="password"
            className={`${
              data.error.password ? "border-red-500" : ""
            } px-4 py-2 focus:outline-none border rounded-md`}
          />
          {!data.error ? "" : alert(data.error.password, "red")}
        </div>
        <div className="flex flex-col">
          <label htmlFor="cPassword" className="text-sm text-gray-600">
            გაიმეორე პაროლი<span className="ml-1">*</span>
          </label>
          <input
            onChange={(e) =>
              setData({
                ...data,
                success: false,
                error: {},
                cPassword: e.target.value,
              })
            }
            autoComplete="new-password"
            value={data.cPassword}
            type="password"
            id="cPassword"
            className={`${
              data.error.cPassword ? "border-red-500" : ""
            } px-4 py-2 focus:outline-none border rounded-md`}
          />
          {!data.error ? "" : alert(data.error.cPassword, "red")}
        </div>
        
          <div>
            <Privacy />
          </div>
        {data.loading ? (
          <div
            type="button"
            className="bg-gray-600 p-2 text-center font-medium text-white rounded-md cursor-wait"
          >
            <svg
              aria-hidden="true"
              className="inline w-6 h-6 mr-2  animate-spin dark:text-gray-600 fill-gray-300"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            დაელოდეთ...
          </div>
        ) : (
          <div
            onClick={(e) => formSubmit()}
            className="font-medium px-4 py-2 text-white text-center cursor-pointer rounded-md bg-[#303031] hover:bg-gray-900"
          >
            რეგისტრაცია
          </div>
        )}
      </form>
    </Fragment>
  );
};

export default Signup;

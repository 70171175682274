import React, { useState, useRef  } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import 'reactjs-popup/dist/index.css';
import Privacy from '../auth/Privacy';
import ProjectSvg from './ProjectSvg.svg'
import { TbArrowBackUp } from "react-icons/tb";
import { useHistory } from "react-router-dom";
// import ProjectUploaded from "../../../styles/assets/projectUpload.svg"

const QuestionF = () => {
  const history = useHistory();
  const inputRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const [state, handleSubmit] = useForm("xrgwjwre");
  const [imageUploaded, setImageUploaded] = useState(false);
  const [imageProjectUploaded1, setImageProjectUploaded1] = useState(false);
  const [imageProjectUploaded2, setImageProjectUploaded2] = useState(false);
  const [imageProjectUploaded3, setImageProjectUploaded3] = useState(false);
  const [imageProjectUploaded4, setImageProjectUploaded4] = useState(false);
  const [profileImage, setProfileImage] = useState();
  const [project1, setProject1] = useState();
  const [project2, setProject2] = useState();
  const [project3, setProject3] = useState();
  const [project4, setProject4] = useState();
  const [checked, setChecked] = useState(false);

  const handleProfileImageChange = (e) => {
    setProfileImage(e.target.files[0]);
    setImageUploaded(true);
  };

  const handleProject1Change = (e) => {
    setProject1(e.target.files[0]);
    setImageProjectUploaded1(true);
  };

  const handleProject2Change = (e) => {
    setProject2(e.target.files[0]);
    setImageProjectUploaded2(true);
  };

  const handleProject3Change = (e) => {
    setProject3(e.target.files[0]);
    setImageProjectUploaded3(true);
  };

  const handleProject4Change = (e) => {
    setProject4(e.target.files[0]);
    setImageProjectUploaded4(true);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (profileImage) {
      formData.append('profileImage', profileImage);
    }
    if (project1) {
      formData.append('project1', project1);
    }
    if (project2) {
      formData.append('project2', project2);
    }
    if (project3) {
      formData.append('project3', project3);
    }
    if (project4) {
      formData.append('project4', project4);
    }

    for (const pair of new FormData(e.target)) {
      formData.append(pair[0], pair[1]);
    }

    handleSubmit(formData);
  };

  if (state.succeeded) {
    return <p className='text-center text-3xl mt-16 ab:mt-4'>თქვენი განაცხადი მიღებულია!</p>;
  }
  return (
    <div className="max-w-2xl mx-auto animate__animated animate__zoomIn font-FiragoB">
      <div
      onClick={(e) => history.push(`/user/forms`)}
      className='text-5xl cursor-pointer'>
      <TbArrowBackUp />
      </div>
      <form onSubmit={onSubmit} encType="multipart/form-data" className="p-6 bg-transparent">
        <div className='grid justify-items-start mb-4'>
          <div className="flex items-center cursor-pointer" onClick={() => inputRef.current.click()}> 
            <label className='text-xs' htmlFor="profileImage">ატვირთე პროფილის ფოტო: </label>
              <svg className={` ${imageUploaded ? 'border-4 border-dotted border-green-800' : ''}`} xmlns="http://www.w3.org/2000/svg" width="116" height="116" viewBox="0 0 116 116" fill="none">
              <path d="M57.9999 66.2857C69.4401 66.2857 78.7142 57.0116 78.7142 45.5715C78.7142 34.1313 69.4401 24.8572 57.9999 24.8572C46.5597 24.8572 37.2856 34.1313 37.2856 45.5715C37.2856 57.0116 46.5597 66.2857 57.9999 66.2857Z" stroke="#545454" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M85.4257 89.0715C77.8547 82.3844 68.1013 78.6938 58 78.6938C47.8986 78.6938 38.1453 82.3844 30.5743 89.0715M111.857 87V103.571C111.857 105.769 110.984 107.876 109.43 109.43C107.876 110.984 105.769 111.857 103.571 111.857H87M87 4.14288H103.571C105.769 4.14288 107.876 5.01584 109.43 6.56971C110.984 8.12359 111.857 10.2311 111.857 12.4286V29M4.14282 29V12.4286C4.14282 10.2311 5.01578 8.12359 6.56965 6.56971C8.12352 5.01584 10.231 4.14288 12.4285 4.14288H29M29 111.857H12.4285C10.231 111.857 8.12352 110.984 6.56965 109.43C5.01578 107.876 4.14282 105.769 4.14282 103.571V87" stroke="#545454" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
          </div>
          <input 
          ref={inputRef} 
          type="file" 
          accept=".png, .jpg, .jpeg" 
          className="w-[92%] mt-2 text-2xl gh:text-xl ab:text-sm hidden" 
          name="profileImage" 
          onChange={handleProfileImageChange} 
           
          />
        </div>

        

        <div className='flex mb-5 smal:flex-col'>
          <div className="w-1/3 smal:w-[100%] mr-5">
           <input
              type="text"
              id="სახელი"
              className="w-full px-3 py-2 border rounded-2xl focus:outline-none focus:border-white"
              name="სახელი"
             placeholder="სახელი"
             required 
            />
            <ValidationError 
            prefix="სახელი" 
            field="სახელი"
            errors={state.errors}
      />
          </div>
          <div className="w-2/3 smal:w-[100%] smal:mt-2">
            <input
              type="text"
              id="გვარი"
              className="w-full px-3 py-2 border rounded-2xl focus:outline-none focus:border-white"
              name="გვარი"
              placeholder="გვარი"
              required 
            />
            <ValidationError 
            prefix="გვარი" 
            field="გვარი"
            errors={state.errors}
      />
          </div>
        </div>
      

        
        <div className="mb-4">
          <input
            type="text"
            id="პროფესია"
            className="w-full px-3 py-2 border rounded-2xl focus:outline-none focus:border-indigo-500"
            name="პროფესია"

            placeholder="საქმიანობა"
             
          />
          <ValidationError 
            prefix="პროფესია" 
            field="პროფესია"
            errors={state.errors}
      />
        </div>
        <div className="mb-4">
          <input
            type="email"
            id="email"
            className="w-full px-3 py-2 border rounded-2xl focus:outline-none focus:border-indigo-500"
            name="email"
            placeholder="მეილი"
             
          />
          <ValidationError 
            prefix="Email" 
            field="email"
            errors={state.errors}
      />
        </div>

        <div className="mb-4">
          <input
            type="tel"
            id="phone"
            className="w-full px-3 py-2 border rounded-2xl focus:outline-none focus:border-indigo-500"
            name="phone"

            placeholder="ტელეფონის ნომერი"
             
          />
          <ValidationError 
            prefix="Phone" 
            field="phone"
            errors={state.errors}
      />
        </div>
        <div className="mb-4">
        <input
          type="text"
          id="portfolio" 
          className="w-full px-3 py-2 border rounded-2xl focus:outline-none focus:border-indigo-500"
          name="portfolio"
          placeholder="მიუთითე პორტფოლიოს ლინკი"  
        />
         <ValidationError 
            prefix="portfolio" 
            field="portfolio"
            errors={state.errors}
      />
      </div>
        <div className="mb-4">
          <textarea
            id="კომენტარი"
            className="w-full px-3 py-2 border rounded-2xl h-36 focus:outline-none focus:border-indigo-500"
            name="კომენტარი"

            placeholder="მოკლედ აღწერე, თუ რა სახის მომსახურებას
            სთავაზობ პოტენციურ მომხმარებელს 
            (მაგ: ვარ მხატვარი, ვხატავ პორტრეტებს. ვმუშაობ
            ზეთის/აკრილის საღებავებით)"
          />
           <ValidationError 
        prefix="კომენტარი" 
        field="კომენტარი"
        errors={state.errors}
      />
        </div>



        
        <div className='flex mb-3 gap-2'>
        <div className="flex items-center cursor-pointer" onClick={() => inputRef1.current.click()}> 
            <svg className={` ${imageProjectUploaded1 ? 'solid rounded-3xl border-2 border-green-500' : ''}`} xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96" fill="none">
  <path d="M60 32H60.04M50 84H24C20.8174 84 17.7652 82.7357 15.5147 80.4853C13.2643 78.2348 12 75.1826 12 72V24C12 20.8174 13.2643 17.7652 15.5147 15.5147C17.7652 13.2643 20.8174 12 24 12H72C75.1826 12 78.2348 13.2643 80.4853 15.5147C82.7357 17.7652 84 20.8174 84 24V50" stroke="#545454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12 64L32 44C35.712 40.428 40.288 40.428 44 44L60 60" stroke="#545454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M56 56L60 52C62.68 49.424 65.8 48.704 68.728 49.84M64 76H88M76 64V88" stroke="#545454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
          </div>
            <input 
            ref={inputRef1} 
            type="file" 
            accept=".png, .jpg, .jpeg" 
            className="w-[92%] mt-2 text-2xl gh:text-xl ab:text-sm hidden" 
            name="project1" onChange={handleProject1Change} 
             />
        
          <div className='grid'>
          
             <div className="flex items-center cursor-pointer" onClick={() => inputRef2.current.click()}> 
            <svg className={` ${imageProjectUploaded2 ? 'solid rounded-3xl border-2 border-green-500' : ''}`} xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96" fill="none">
  <path d="M60 32H60.04M50 84H24C20.8174 84 17.7652 82.7357 15.5147 80.4853C13.2643 78.2348 12 75.1826 12 72V24C12 20.8174 13.2643 17.7652 15.5147 15.5147C17.7652 13.2643 20.8174 12 24 12H72C75.1826 12 78.2348 13.2643 80.4853 15.5147C82.7357 17.7652 84 20.8174 84 24V50" stroke="#545454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12 64L32 44C35.712 40.428 40.288 40.428 44 44L60 60" stroke="#545454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M56 56L60 52C62.68 49.424 65.8 48.704 68.728 49.84M64 76H88M76 64V88" stroke="#545454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
          </div>
        
            <input 
            ref={inputRef2} 
            type="file" 
            accept=".png, .jpg, .jpeg" 
            className="w-[92%] mt-2 text-2xl gh:text-xl ab:text-sm hidden" 
            name="project2" onChange={handleProject2Change} 
              />
          </div>


        <div className='grid'>
        <div className="flex items-center cursor-pointer" onClick={() => inputRef3.current.click()}> 
            <svg className={` ${imageProjectUploaded3 ? 'solid rounded-3xl border-2 border-green-500' : ''}`} xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96" fill="none">
  <path d="M60 32H60.04M50 84H24C20.8174 84 17.7652 82.7357 15.5147 80.4853C13.2643 78.2348 12 75.1826 12 72V24C12 20.8174 13.2643 17.7652 15.5147 15.5147C17.7652 13.2643 20.8174 12 24 12H72C75.1826 12 78.2348 13.2643 80.4853 15.5147C82.7357 17.7652 84 20.8174 84 24V50" stroke="#545454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12 64L32 44C35.712 40.428 40.288 40.428 44 44L60 60" stroke="#545454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M56 56L60 52C62.68 49.424 65.8 48.704 68.728 49.84M64 76H88M76 64V88" stroke="#545454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
          </div>
        <input 
        ref={inputRef3} 
        type="file" 
        accept=".png, .jpg, .jpeg" 
        className="w-[92%] mt-2 text-2xl gh:text-xl ab:text-sm hidden" 
        name="project3" 
        onChange={handleProject3Change}   />
        </div>

        <div className='grid'>
        <div className="flex items-center cursor-pointer" onClick={() => inputRef4.current.click()}> 
            <svg className={` ${imageProjectUploaded4 ? 'solid rounded-3xl border-2 border-green-500' : ''}`} xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96" fill="none">
  <path d="M60 32H60.04M50 84H24C20.8174 84 17.7652 82.7357 15.5147 80.4853C13.2643 78.2348 12 75.1826 12 72V24C12 20.8174 13.2643 17.7652 15.5147 15.5147C17.7652 13.2643 20.8174 12 24 12H72C75.1826 12 78.2348 13.2643 80.4853 15.5147C82.7357 17.7652 84 20.8174 84 24V50" stroke="#545454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12 64L32 44C35.712 40.428 40.288 40.428 44 44L60 60" stroke="#545454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M56 56L60 52C62.68 49.424 65.8 48.704 68.728 49.84M64 76H88M76 64V88" stroke="#545454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
          </div>
        <input 
        ref={inputRef4} 
        type="file" 
        accept=".png, .jpg, .jpeg" 
        className="w-[92%] mt-2 text-2xl gh:text-xl ab:text-sm hidden" 
        name="project4" 
        onChange={handleProject4Change}  />
        </div>
    </div>
        <Privacy />
        <button className='p-2 w-40 border-black text-white font-bold border rounded-3xl bg-[#3749EA]' type="submit" disabled={!checked && state.submitting}>
        გაგზავნა
      </button>
      </form>
    </div>
    
  );
};

export default QuestionF; 

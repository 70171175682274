import React from 'react';
import { useHistory, useLocation } from "react-router-dom";

const EachBlog = ({ imageUrl, header, text }) => {
    const history = useHistory();

  return (
    <div className="flex">
      <div className='mr-6'>
        <img src={imageUrl} alt='Blog' className='m-10 rounded-xl  max-w-none gh:h-32 cd:hidden' />
      </div>
      <div className='flex flex-col p-10 ef:p-5'>
        <h1 className='text-lg font-semibold'>{header}</h1>
        <p className="mt-2 text-base gh:text-sm ef:text-xs">{text}</p>
        <bottom
         onClick={(e) => history.push("/Blog1")}
         className="mt-3 border border-black border-solid p-2 w-fit rounded-3xl ef:p-1 cursor-pointer">გაიგეთ მეტი</bottom>
      </div>
    </div>
  );
};

export default EachBlog;
// Change Lorem in 72 and 80 lines with your website name

import React, { Fragment, useContext,useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./style.css";
import { logout } from "./Action";
import { LayoutContext } from "../index";
import { isAdmin } from "../auth/fetchApi";

const Navber = (props) => {
  const history = useHistory();
  const location = useLocation();
  
  const isCoursePage = location.pathname.startsWith('/course');
  const [isScrolled, setIsScrolled] = useState(false);

  const { data, dispatch } = useContext(LayoutContext);
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 90);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };
  const navberToggleOpen = () =>
    data.navberHamburger
      ? dispatch({ type: "hamburgerToggle", payload: false })
      : dispatch({ type: "hamburgerToggle", payload: true });

  const loginModalOpen = () =>
    data.loginSignupModal
      ? dispatch({ type: "loginSignupModalToggle", payload: false })
      : dispatch({ type: "loginSignupModalToggle", payload: true });

  // const cartModalOpen = () =>
  //   data.cartModal
  //     ? dispatch({ type: "cartModalToggle", payload: false })
  //     : dispatch({ type: "cartModalToggle", payload: true });

  return (
    <Fragment>
      {/* Navber Section */}
      <nav className={`${isCoursePage ? `${isScrolled ?  "bg-black": "bg-transparent" }` : "bg-dark"} fixed top-0 w-screen z-20 shadow-lg lg:shadow-none text-sun`}>
        <div className="m-4 md:mx-12 md:my-6 block ef:flex ef:justify-between">
          
        <div className="col-span-2 lg:hidden flex justify-items-stretch	 items-center">
            <svg
              onClick={(e) => navberToggleOpen()}
              className="col-span-1 lg:hidden w-8 h-8 cursor-pointer "
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
            <span
              onClick={(e) => history.push("/")}
              style={{ letterSpacing: "0.10rem" }}
              className="flex items-left text-center font-bold uppercase text-gray-800 text-2xl max-[340px]:text-xl cursor-pointer px-2 "
            >
    
            </span>
          </div>
          
          <div className="flex text-white items-right col-span-2 lg:col-span-1 items-center justify-between">
            {/*  WishList Page Button */}
            <div className="hidden lg:block col-span-1 ">
            <span
              className=" px-4 py-3 rounded-lg tracking-widest  cursor-pointer"
              onClick={(e) => history.push("/")}
            >
              მთავარი
            </span>
              <span className="px-4 py-3 rounded-lg tracking-widest  cursor-pointer"
              onClick={(e) => history.push("/Hamqari")}
              >
                ჰამქარი
              </span>
            <span
              className=" px-4 py-3 rounded-lg tracking-widest  cursor-pointer"
              onClick={(e) => history.push("/jober")}
            >
              ვაკანსია
            </span>
            <span
              className=" px-4 py-3 rounded-lg tracking-widest  cursor-pointer"
              onClick={(e) => history.push("/Faq")}
            >
              პასუხები
            </span>
            <div
                className="relative inline-block"
                onMouseEnter={toggleDropdown}
                onMouseLeave={closeDropdown}
              >
                <span
                  className=" px-4 py-3 rounded-lg tracking-widest  cursor-pointer"
                  
                >
                  ჩვენს შესახებ
                </span>
                {dropdownVisible && (
                  <ul className="absolute mt-2 text-dark bg-white border border-gray-300 rounded-lg shadow-lg">
                    <li
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100 hover:rounded-lg"
                      onClick={(e) => {
                        history.push("/aboutUs");
                        closeDropdown();
                      }}
                    >
                      ჩვენს შესახებ
                    </li>
                    <li
                      className="px-4 py-2 cursor-pointer hover:bg-gray-100 hover:rounded-lg"
                      onClick={(e) => {
                        history.push("/blogs");
                        closeDropdown();
                      }}
                    >
                      ბლოგი
                    </li>
                  </ul>
                )}
              </div>
            
            {/* <span
              className=" px-4 py-3 rounded-lg  tracking-widest  cursor-pointer"
              onClick={(e) => history.push("/contact-us")}
            >
              კონტაქტი
            </span> */}
          </div>
            {/* <div
              onClick={(e) => history.push("/wish-list")}
              className="hover:bg-gray-200 rounded-lg px-2 py-2 max-[340px]:px-[3px] cursor-pointer"
              title="Wishlist"
            >
              <svg
                className={`${
                  location.pathname === "/wish-list"
                    ? "fill-current text-gray-800"
                    : ""
                } w-8 h-8 text-gray-600 cursor-pointer`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                />
              </svg>
            </div> */}
            
            {localStorage.getItem("jwt") ? (
              <Fragment>
                <div className="userDropdownBtn  px-2 py-2 rounded-lg relative max-[340px]:px-[3px]">
                  <svg
                    className="cursor-pointer w-8 h-8 "
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <div className="userDropdown absolute right-0 mt-1 text-dark bg-gray-200 rounded">
                    {!isAdmin() ? (
                      <Fragment>
                        <li className="flex flex-col  w-48 shadow-lg">
                          <span
                            onClick={(e) => history.push("/user/forms")}
                            className="flex space-x-2 py-2 px-8  cursor-pointer"
                          >
                            <span>
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                />
                              </svg>
                            </span>
                            <span>პროფილი</span>
                          </span>
                          <span
                            onClick={(e) => history.push("/wish-list")}
                            className="flex space-x-2 py-2 px-8  cursor-pointer"
                          >
                            <span>
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                                />
                              </svg>
                            </span>
                            <span>რჩეულნი</span>
                          </span>
                          <span
                            onClick={(e) => history.push("/user/setting")}
                            className="flex space-x-1 py-2 px-8 cursor-pointer"
                          >
                            <span>
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                            </span>
                            <span>პარამეტრები</span>
                          </span>
                          <span
                            onClick={(e) => logout()}
                            className="flex space-x-2 py-2 px-8  cursor-pointer"
                          >
                            <span>
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                                />
                              </svg>
                            </span>
                            <span>გამოსვლა</span>
                          </span>
                        </li>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <li className="flex flex-col  w-48 shadow-lg">
                          <span
                            onClick={(e) => history.push("/admin/dashboard")}
                            className="flex space-x-2 py-2 px-8  cursor-pointer"
                          >
                            <span>
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                            </span>
                            <span>ადმინი</span>
                          </span>
                          <span
                            onClick={(e) => logout()}
                            className="flex space-x-2 py-2 px-8  cursor-pointer"
                          >
                            <span>
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                                />
                              </svg>
                            </span>
                            <span>გამოსვლა</span>
                          </span>
                        </li>
                      </Fragment>
                    )}
                  </div>
                </div>
              </Fragment>
            ) : (
              /* Login Modal Button */
              <div
                onClick={(e) => loginModalOpen()}
                className="cursor-pointer px-10 py-2 max-[340px]:px-[3px] rounded-lg bg-white text-Herbs"
                title="Login"
              >
                დარეგისტრირდი
          
              </div>
            )}
            {/* Cart Modal Button */}
            {/* <div
              onClick={(e) => cartModalOpen()}
              className=" px-2 py-0 rounded-lg relative cursor-pointer max-[340px]:px-[3px]"
              title="Cart"
            >
              <svg
                className="w-8 h-8 "
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                />
              </svg>
              <span className="absolute top-0 ml-6 mt-1 bg-sun rounded px-1 text-white text-xs ">
                {data.cartProduct !== null ? data.cartProduct.length : 0}
              </span>
            </div> */}
          </div>
        </div>
        <div
          className={
            data.navberHamburger && data.navberHamburger
              ? "px-1 pb-2 md:pb-0 md:px-10 lg:hidden"
              : "hidden px-1 pb-2 md:pb-0 md:px-10 lg:hidden"
          }
        >
          <div className="col-span-1 flex flex-col text-white">
            <span
              className="font-medium text-lg tracking-widest  px-3 py-2 rounded-lg cursor-pointer"
              onClick={(e) => history.push("/Hamqari")}
            >
              ჰამქარი
            </span>
            <span
              className="font-medium text-lg tracking-widest  px-3 py-2 rounded-lg cursor-pointer"
              onClick={(e) => history.push("/jober")}
            >
              ვაკანსია
            </span>

            <span
              className="font-medium text-lg tracking-widest  px-3 py-2 rounded-lg cursor-pointer"
              onClick={(e) => history.push("/Faq")}
            >
              პასუხები
            </span>
            <span
              className="font-medium text-lg tracking-widest  px-3 py-2 rounded-lg cursor-pointer"
              onClick={(e) => history.push("/aboutUs")}
            >
              ჩვენს შესახებ
            </span>
            <span
              className="font-medium text-lg tracking-widest  px-3 py-2 rounded-lg cursor-pointer"
              onClick={(e) => history.push("/blogs")}
            >
              ბლოგი
            </span>
          </div>
        </div>
      </nav>
      {/* End Navber Section */}
    </Fragment>
  );
};

export default Navber;

import React from 'react'
import Layout from '../layout';
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";
import { useState } from "react";
import FaqText from './FaqText';

const Faqq = () => {
  return (
    <>
      <div className="mt-32 mb-[44rem] cd:mb-32 ab:mt-40 normal-case">
      <div className='flex w-fit mx-auto  p-5 text-black text-4xl rounded-3xl border border-solid border-black '>
           <p>კითხვები და პასუხები</p> 
        </div>

        <div className="flex flex-col lg:hidden">
          <FaqText
            text={true}
            margin="md:mt-6 mt-5"
            header='რეგისტრაცია'
            answer={`რომ დასაქმდე \u000A იმისთვის რომ დარეგისტრირდე და გახდე “ჰამქარი”, რათა შესთავაზო პოტენციურ მომხმარებელს, შენი მომსახურება \u000A \u000A რომ დაასაქმო იმისთვის რომ დარეგისტრირდე და მოძებნო ან დაიქირავო “ჰამქარი”, რათა შესთავაზო პოტენციურ მომხმარებელს, შენი მომსახურება\u000A`}
          />
          <FaqText
            text={true}
            margin="md:mt-6 mt-5"
            header="საქმიანობა"
            answer={`
            www.hamqari.com-ის საშუალებით, მომხმარებელს
            შეგიძლია შესთავაზო, შენთვის სასურველი
            მომსახურება - შენთვის სასურველი ანაზღაურების
            სანაცვლოდ!
            მაგალითად:
            ⚫ ვამზადებ ხელნაკეთ ნივთებს
            ⚫ ვარ გრაფიკული დიზაინერი
            ⚫ შევასწავლი უცხო ენას
            ⚫ დავალაგებ ბინას
            ⚫ და მრავალი, მრავალი სხვა...`}
          />
          <FaqText
                text={true}
                margin="md:mt-6 mt-5"
                header="პროფილის ფოტო"
                answer={`ატვირთე ფოტოსურათი, რომელზეც გამოკვეთილად ჩანს შენი სახე - სტატისტიკურად, მომხმარებელში მეტ ნდობას იწვევს, როდესაც აქვს შენი ვიზუალური აღქმა.`}
              />
              <FaqText
                text={true}
                margin="md:mt-6 mt-5"
                header="ნამუშევრები"
                answer={`ატვირთე მხოლოდ საკუთარი ნამუშევრების ამსახველი ფოტო / რენდერი. \u000A გაითვალისწინე - ხელოვნური ინტელექტის მიერ დაგენერირებული ნამუშევრების, ასევე სხვისი ნამუშევრების საკუთარ პროდუქტად გასაღების შემთხვევაში - ფოტოები/რენდერები წაიშლება შენი პროფილიდან! \u000A თუ, ვინმეს პროფილზე იხილავ მსგავს ფოტოს დაარეპორტე - ადმინისტრაცია განიხილავს და შეამოწმებს მათ.`}
              />
              <FaqText
                text={true}
                margin="md:mt-6 mt-5"
                header="პორტფოლიო"
                answer={
                  "შენს განცხადებაში მიუთითე, მხოლოდ შენი საკუთარი ნამუშევრების პორტფოლიო. წინააღმდეგ შემთხვევაში ის წაიშლება შენი პროფილიდან! \u000Aგაითვალისწინე - ხელოვნური ინტელექტის მიერ დაგენერირებული ნამუშევრების, ასევე სხვისი ნამუშევრების საკუთარ პროდუქტად გასაღების შემთხვევაში - პორფოლიო წაიშლება შენი პროფილიდან! \u000Aთუ, ვინმეს პროფილზე იხილავ მსგავს ფოტოს დაარეპორტე - ადმინისტრაცია განიხილავს და შეამოწმებს მათ."
                }
              />
          <FaqText
            text={false}
            margin="mt-0"
            header="რა ღირს?"
            answer={
              <p>
                <a className='text-sky-600' href="http://www.hamqari.com" target="_blank" rel="noopener noreferrer">
                  www.hamqari.com
                </a>
                -ზე რეგისტრაცია არის სრულიად უფასო!
              </p>
            }
          />
        </div>




        <div className="lg:flex flex-row hidden">
          <div className="flex flex-col">
            <div className="absolute left-[82px] w-[42%] mb-32">
              <FaqText
                text={true}
                margin="mt-6"
                header='რეგისტრაცია'
                answer={`რომ დასაქმდე \u000A იმისთვის რომ დარეგისტრირდე და გახდე “ჰამქარი”, რათა შესთავაზო პოტენციურ მომხმარებელს, შენი მომსახურება \u000A \u000A რომ დაასაქმო იმისთვის რომ დარეგისტრირდე და მოძებნო ან დაიქირავო “ჰამქარი”, რათა შესთავაზო პოტენციურ მომხმარებელს, შენი მომსახურება\u000A`}
              />
              <FaqText
                text={true}
                margin="mt-8"
                header="საქმიანობა"
                answer={`
                www.hamqari.com-ის საშუალებით, მომხმარებელს
                შეგიძლია შესთავაზო, შენთვის სასურველი
                მომსახურება - შენთვის სასურველი ანაზღაურების
                სანაცვლოდ!
                მაგალითად:
                ⚫ ვამზადებ ხელნაკეთ ნივთებს
                ⚫ ვარ გრაფიკული დიზაინერი
                ⚫ შევასწავლი უცხო ენას
                ⚫ დავალაგებ ბინას
                ⚫ და მრავალი, მრავალი სხვა...`}
              />
              <FaqText
                text={false}
                margin="mt-0"
                header="პროფილის ფოტო"
                answer={`ატვირთე ფოტოსურათი, რომელზეც გამოკვეთილად ჩანს შენი სახე - სტატისტიკურად, მომხმარებელში მეტ ნდობას იწვევს, როდესაც აქვს შენი ვიზუალური აღქმა.`}
              />
            </div>
          </div>

          <div className="flex flex-col">
            <div className="absolute right-[82px] w-[43%] mb-32">
              <FaqText
                text={true}
                margin="mt-6"
                header="ნამუშევრები"
                answer={`ატვირთე მხოლოდ საკუთარი ნამუშევრების ამსახველი ფოტო / რენდერი. \u000A გაითვალისწინე - ხელოვნური ინტელექტის მიერ დაგენერირებული ნამუშევრების, ასევე სხვისი ნამუშევრების საკუთარ პროდუქტად გასაღების შემთხვევაში - ფოტოები/რენდერები წაიშლება შენი პროფილიდან! \u000A თუ, ვინმეს პროფილზე იხილავ მსგავს ფოტოს დაარეპორტე - ადმინისტრაცია განიხილავს და შეამოწმებს მათ.`}
              />
              <FaqText
                text={true}
                margin="mt-8"
                header="პორტფოლიო"
                answer={
                    "შენს განცხადებაში მიუთითე, მხოლოდ შენი საკუთარი ნამუშევრების პორტფოლიო. წინააღმდეგ შემთხვევაში ის წაიშლება შენი პროფილიდან! \u000Aგაითვალისწინე - ხელოვნური ინტელექტის მიერ დაგენერირებული ნამუშევრების, ასევე სხვისი ნამუშევრების საკუთარ პროდუქტად გასაღების შემთხვევაში - პორფოლიო წაიშლება შენი პროფილიდან! \u000Aთუ, ვინმეს პროფილზე იხილავ მსგავს ფოტოს დაარეპორტე - ადმინისტრაცია განიხილავს და შეამოწმებს მათ."
                  }
              />
              <FaqText
                text={false}
                margin="mt-0"
                header="რა ღირს"
                answer={
                  <p>
                    <a className='text-sky-600' href="http://www.hamqari.com" target="_blank" rel="noopener noreferrer">
                      www.hamqari.com
                    </a>
                    -ზე რეგისტრაცია არის სრულიად უფასო!
                  </p>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


const Faq = () => {
    return <Layout children={<Faqq />} />;
  };
export default Faq
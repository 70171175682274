import React from 'react'
import Layout from '../layout';

const AboutUss = () => {
  return (
    <div className='mt-28 m-12 aboutUsBack p-14 font-semibold cd:m-4 cd:text-xs cd:mt-[120px] aboutUsDiv cd:p-3'>
        <p className='mb-10'>ვინ ვართ და საერთოდ რას წარმოადგენს ჩვენი გუნდი?</p>
        <p className='multiline mb-3'>კეთილი... მაშინ მოდი გულწრფელები ვიქნებით და არ დაგიწყებთ მარკეტინგული ხრიკებით და დავარცხნილი
        ტექსტების, მოვალეობის მოხდის მიზნით დაწერას: </p>

        <p className='multiline mb-3'> ჩვენ ვართ ქართველი დიზაინერებისა და დეველოპერების გუნდი. </p>

        <p className='multiline mb-3'> ჩვენ ვხედავთ და ვაფასებთ შენს ნიჭსა და მონდომებას - რადგან ზუსტად ვიცით რა შრომის ფასად 
        მიაღწიეთ შედეგს.  </p> 

        <p className='mb-3'> ჩვენ, შევქმენით ის, რაც დღეს საქართველოს ყველაზე მეტად სჭირდება - ღირსეული შემოსავლის მიღების
        წყარო.  </p>

        <p className='mb-3'> ჩვენი გუნდი ყოველთვის ერთგულად იდგება შენს სამსახურში.  </p>

        <p className='mb-3'> ჩვენი გუნდი შენი გულშემატკივარია და წარმატებას გისურვებს! </p>

        <p className='mb-3'>გახსოვდეს - შენი წარმატება ნიშნავს ჩვენს წარმატებას!</p>

    </div>
  )
}

const AboutUs = () => {
    return <Layout children={<AboutUss />} />;
  };

export default AboutUs
import React, { Fragment, useEffect, useContext, useState } from "react";
import OrderSuccessMessage from "./OrderSuccessMessage";
import { HomeContext } from "./";
import { sliderImages } from "../../admin/dashboardAdmin/Action";
import { nextSlide, prevSlide } from "./Mixins";
import { useHistory, useLocation } from "react-router-dom";
import { isAuthenticate } from "../auth/fetchApi";

const apiURL = process.env.REACT_APP_API_URL;

const Slider = (props) => {
  const history = useHistory();
  const { data, dispatch } = useContext(HomeContext);
  const [slide, setSlide] = useState(0);

  useEffect(() => {
    sliderImages(dispatch);
    // Set up a timer to automatically change the slide every 10 seconds
    const intervalId = setInterval(() => {
      if (data.sliderImages.length > 1) {
        nextSlide(data.sliderImages.length, slide, setSlide);
      }
    }, 10000);
    // Clear the interval when component is unmounted
    return () => clearInterval(intervalId);
  }, [dispatch, data.sliderImages.length, slide]);

  // const handleClickScroll = () => {
  //   const element = document.getElementById("shop");
  //   if (element) {
  //     // 👇 Will scroll smoothly to the top of the next section
  //     element.scrollIntoView({ behavior: "smooth", block: "center" });
  //   }
  // };

  return (
    <Fragment>
      <div className=" mt-20  ab:mt-0 ">
        {/* {data.sliderImages.length > 0 ? ( */}
          <div className="">
            <div className="">
              <div className="mt-[110px] text-center">
                <div className="mb-16">
                  <h3 className="inline-block px-10 py-3 text-black animatte text-sm"> საიტის ბეტა ვერსია</h3>
               </div>
               <div className="inline-block ">
                  <h1 className="text-5xl ab:text-3xl">
                    ეძებ სამსახურს ?
                  </h1> 
                  <h1 className="text-5xl ab:text-3xl">
                    ჰამქარი დაგეხმარება
                  </h1> 
                  <p className="text-base mt-4">
                  შესთავაზე 3 000 000 -ზე მეტ პოტენციურ მომხმარებელს <span className="block">
                  შენი მომსახურება ან თავად დაასაქმე შენი რჩეული ჰამქარი
                  </span>
                  </p>
               </div>
               <div className=" mt-20 mb-14 grid justify-center">
            
                  {isAuthenticate() ? (
        <button
       className="text-2xl bg-shavi text-white px-7 py-1  rounded-[70px]"
       type="">
        მოგესალმებით!
       </button>
      ) : (
        <div className="text-2xl bg-shavi text-white px-7 py-1  rounded-[70px] ">
          დარეგისტრირდი
        </div>
      )}
               </div>
            </div>

            
            </div>
          </div>
        {/* ) : (
          ""
        )} */}

          <>
            {/* {data.sliderImages.length > 1 ? (
              <div className="absolute inset-0 flex items-center justify-between">
                <svg
                  onClick={(e) =>
                    prevSlide(data.sliderImages.length, slide, setSlide)
                  }
                  className={`z-10  flex justify-end items-center box-border w-12 h-12 text-gray-700  cursor-pointer hover:text-yellow-700`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
                <svg
                  onClick={(e) =>
                    nextSlide(data.sliderImages.length, slide, setSlide)
                  }
                  className={`z-10 flex justify-start items-center box-border w-12 h-12 text-gray-700 cursor-pointer hover:text-yellow-700`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </div>
            ) : (
              ""
            )} */}
           
          </>
      </div>
      <OrderSuccessMessage />
    </Fragment>
  );
};

export default Slider;

import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  Fragment,
  useReducer,
} from "react";
import { useHistory } from "react-router-dom";
import { getAllProduct } from "../../admin/products/FetchApi";
import { HomeContext } from "./index";
import { isWishReq, unWishReq, isWish } from "./Mixins";
import "./style.css";
import Star from "../../../styles/assets/star.svg";
import Layout from "../layout";
import { homeState, homeReducer } from "./HomeContext";
import { TfiWrite } from "react-icons/tfi";
import { 
  GiMuscularTorso, 
  GiJewelCrown, 
  GiFilmProjector, 
  GiTeacher
} from 'react-icons/gi';
import { FaSkiing, FaChessKnight, FaLanguage, FaEthereum  } from 'react-icons/fa';
import { MdEngineering, MdPhotoCamera  } from 'react-icons/md';
import { ImEarth  } from "react-icons/im";
import { PiCodeFill, PiMathOperationsFill  } from "react-icons/pi";
import { SiAdobeindesign } from "react-icons/si";


export const categories = [
  {
    label: 'ყველა',
    icon: ImEarth,
    description: 'This property is close to the beach!',
    filterr: '',
  },
  {
    label: 'პროგრამირება',
    icon: PiCodeFill,
    description: 'This property is close to the beach!',
    filterr: 'დეველოპერი',
  },
  {
    label: 'დიზაინი',
    icon: SiAdobeindesign,
    description: 'This property has windmills!',
    filterr: 'გრაფიკული დიზაინერი',
  },
  // {
  //   label: '3D დიზაინი',
  //   icon: MdOutlineVilla,
  //   description: 'This property is modern!'
  // },
  // {
  //   label: '2D დიზაინი',
  //   icon: TbMountain,
  //   description: 'This property is in the countryside!'
  // },
  {
    label: 'ფოტოგრაფი',
    icon: MdPhotoCamera ,
    description: 'This property has a beautiful pool!',
    filterr: 'ფოტოგრაფი'
  },
  {
    label: 'ვიდეოგრაფი',
    icon: GiFilmProjector,
    description: 'This property is on an island!',
    filterr: 'ვიდეოგრაფი'
  },
  {
    label: 'მენტორი',
    icon: GiTeacher,
    description: 'This property is near a lake!',
    filterr: 'მენტორი'
  },
  {
    label: 'ქოფირაითერი',
    icon: TfiWrite,
    description: 'This property has skiing activities!',
    filterr: 'ქოფირაითერი'
  },
  {
    label: 'ელექტროინჟინერი',
    icon: MdEngineering,
    description: 'This property is an ancient castle!',
    filterr: 'ელექტროინჟინერი'
  },
  {
    label: 'მოქანდაკე',
    icon: GiMuscularTorso,
    description: 'This property is in a spooky cave!',
    filterr: 'მოქანდაკე'
  },
  {
    label: 'ბლოკჩეინი',
    icon: FaEthereum,
    description: 'This property is in a spooky cave!',
    filterr: 'ბლოკჩეინი'
  },
  {
    label: 'ხელნაკეთი',
    icon: GiJewelCrown,
    description: 'This property offers camping activities!',
    filterr: 'ხელნაკეთი'
  }


  // {
  //   label: 'ბუღალტერი',
  //   icon: PiMathOperationsFill ,
  //   description: 'This property is in the desert!',
  //   filterr: 'ბუღალტერი'
  // }


  // {
  //   label: 'Hr',
  //   icon: GiBarn,
  //   description: 'This property is in a barn!'
  // },
  // {
  //   label: 'Lux',
  //   icon: IoDiamond,
  //   description: 'This property is brand new and luxurious!'
  // }
];

const Hamqarii = (props) => {
  const { data, dispatch } = useContext(HomeContext);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [newProducts, setnewProducts] = useState([]);
  const [useProducts, setuseProducts] = useState([]);
  const [filtery, setFilter] = useState(''); 
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if(filtery === "") {
      setuseProducts(newProducts); 
    } else {
      const filtered = newProducts.filter(p => p.pCategory.cName === filtery);
      setuseProducts(filtered);
    }
  }, [filtery, newProducts]);
  
  const handleFilter = (profession, label) => {
    setFilter(profession); 
    setSelectedCategory(label);
  }

  const fetchData = useCallback(async () => {
    dispatch({ type: "loading", payload: true });
    try {
      let responseData = await getAllProduct();
      setTimeout(() => {
        if (responseData && responseData.Products) {
          let fproducts = responseData.Products.filter(
            (produc) => produc.pStatus === "Active"
          );
          const newProductes = fproducts.sort((a, b) => {
            return new Date(b.pDate) - new Date(a.pDate);
          });
          setuseProducts(newProductes);
          setnewProducts(newProductes)
         

          dispatch({ type: "setProducts", payload: fproducts });
          dispatch({ type: "loading", payload: false });
        }
      }, 300);
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (data.loading) {
    return (
      <div className="col-span-2 md:col-span-3 lg:col-span-4 flex items-center justify-center py-24">
        <svg
          className="w-12 h-12 animate-spin text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          ></path>
        </svg>
      </div>
    );
  }

  
  return (
    <Fragment>
      <div>
      <div className="mt-28  ">
      <div className="pt-4 flex flex-row items-center justify-start overflow-x-auto px-3 ">
        {categories.map(category => (
          <div 
            key={category.label}
            className={`flex flex-col items-center justify-center gap-2 p-3 cursor-pointer ${
              selectedCategory === category.label ? 'bg-white' : 'bg-[#f0f0f0]'
            }`}
            onClick={() => handleFilter(category.filterr, category.label)}
            style={{
              borderRadius: '10px 10px 0px 0px', // Border radius for a rounded look
              boxShadow: '5px 5px 10px #bfbfbf, -5px -5px 10px #ffffff', // Box shadow for Neumorphism effect
            }}
          >
            <category.icon size={26} />
            <h2 className="font-medium text-sm">{category.label}</h2>
          </div>
        ))}
      </div>


     
      </div>


        <div className="mt-1 grid grid-cols-6 ek:grid-cols-5 gh:grid-cols-4 ef:grid-cols-3 ef:p-6 hh:grid-cols-2 hh:p-16 cd:grid-cols-1 cd:p-16 gap-3 p-24 ">
      <ProductList products={useProducts} />
      </div>
      {/* designers */}
       

      </div>
    </Fragment>
  );
};

const ProductList = ({ products }) => {
  const [wList, setWlist] = useState(
    JSON.parse(localStorage.getItem("wishList"))
  );
  const history = useHistory();

  return (
    <Fragment>
     
      {products && products.length > 0 ? (
        products.map((item, index) => {
          return (
          <div key={index}>
              <div className="grid justify-items-center mb-6 relative col-span-1 border-solid border rounded-3xl border-black-rgba w-64 h-[460px]">
                <div className="p-12 ">
                  <img
                    onClick={(e) => history.push(`/products/${item._id}`)}
                    className="w-32 h-32 object-cover object-center cursor-pointer rounded-full  "
                    src={item.pImages[0]}
                    alt="profile"
                  />
                  
                </div>
                {/* WhisList Logic End */}
                <div className="flex items-center ">
                  <div
                    className="text-shavi  text-base leading-tight break-words cursor-pointer "
                    onClick={(e) => history.push(`/products/${item._id}`)}
                  >
                    <p className="text-xl text-center font-semibold">{item.pName}</p>
                    <p className="text-base text-center text-lips">{item.pCreatorName}</p>
                    <div className="flex justify-center"> 
                    <img className="mr-1" alt="star" src={Star} />
                    <img className="mr-1" alt="star" src={Star} />
                    <img className="mr-1" alt="star" src={Star} />
                    <img className="mr-1" alt="star" src={Star} />
                    <img className="mr-1" alt="star" src={Star} />
                    </div>
                   
                  </div>
                  {/* <div
                    className="flex items-center space-x-1 ml-4 mb-5 mr-2"
                    title="All review"
                  >
                    <span>
                      <svg
                        className="md:w-5 md:h-5 w-4 h-4 fill-current  text-yellow-700"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                        />
                      </svg>
                    </span>
                    <span className="text-gray-700">
                      {item.pRatingsReviews.length}
                    </span>
                  
                  </div> */}
                </div>


                {/* <div className="ml-7 text-xs  flex break-all mr-12 mb-3">
                {item.pDescription}
                </div> */}


               {/* <div className="roman text-xs ml-7 mb-2">
               Student (589)	Rating (4.6)
               </div> */}

                {item.pOffer !== 0 && item.pOffer !== "0" ? (
                  
                <div className="grid">
                  {/* <p className="  text-base ml-7 bolda text-just bg-sky center  rounded-md text-center px-3">
                    {" "}
                    {item.pPrice} GEL / Hr
                  </p> */}
                  <button 
                  onClick={(e) => history.push(`/products/${item._id}`)}
                  className="bg-[#0085FF] text-white text-[23px] text-center px-10 mb-10  rounded-3xl">
                    მეტი
                  </button>

                  {/* WhisList Logic  */}
                  {/* <div className=" mx-2 my-2 md:mx-4">
                    <svg
                      onClick={(e) => isWishReq(e, item._id, setWlist)}
                      className={`${
                        isWish(item._id, wList) && "hidden"
                      } w-5 h-5 md:w-6 md:h-6 cursor-pointer text-yellow-700 transition-all duration-300 ease-in`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                      />
                    </svg>
                    <svg
                      onClick={(e) => unWishReq(e, item._id, setWlist)}
                      className={`${
                        !isWish(item._id, wList) && "hidden"
                      } w-6 h-6 md:w-7 md:h-7 cursor-pointer text-yellow-700 transition-all duration-300 ease-in`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div> */}
                  </div>
                ) : (
                  <div className="flex items-center justify-between">
                  <p className="  text-base ml-7 bolda text-just bg-sky center  rounded-3xl text-center px-3">
                    {" "}
                    Buy ₾{item.pPrice} GEL
                  </p>

                  {/* WhisList Logic  */}
                  <div className=" mx-2 my-2 md:mx-4">
                    <svg
                      onClick={(e) => isWishReq(e, item._id, setWlist)}
                      className={`${
                        isWish(item._id, wList) && "hidden"
                      } w-5 h-5 md:w-6 md:h-6 cursor-pointer text-yellow-700 transition-all duration-300 ease-in`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                      />
                    </svg>
                    <svg
                      onClick={(e) => unWishReq(e, item._id, setWlist)}
                      className={`${
                        !isWish(item._id, wList) && "hidden"
                      } w-6 h-6 md:w-7 md:h-7 cursor-pointer text-yellow-700 transition-all duration-300 ease-in`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  </div>
                )}
              </div>
            
            </div>
          );
        })
      ) : (
        <div className="col-span-2 md:col-span-3 lg:col-span-4 flex items-center justify-center py-24 text-2xl">
          არ მოიძებნა
        </div>
      )}
    </Fragment>
  );
};

const Hamqari = (props) => {
  const [data, dispatch] = useReducer(homeReducer, homeState);
  return (
    <Fragment>
      <HomeContext.Provider value={{ data, dispatch }}>
        <Layout children={<Hamqarii />} />
      </HomeContext.Provider>
    </Fragment>
  );
};


export default Hamqari;
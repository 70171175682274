import React from 'react'
import Layout from '../layout';
import EachBlog from './EachBlog';
import Blog1 from "../../../styles/assets/Blog1.png"
import Blog2 from "../../../styles/assets/Blog2.png"

const Blogss = () => {
  const blogsData = [
    {
      imageUrl: Blog1,
      header: 'თავისუფლების სინონიმი: Freelance',
      text: 'მისი მნიშვნელობა და დადებითი მხარეები: თანამედროვე სამუშაო ძალის მუდმივად განვითარებად სამყაროში, ფრილანსირების კონცეფცია გაჩნდა, როგორც ტრადიციული დასაქმების ძლიერი და განმათავისუფლებელი ალტერნატივა. Freelancing, ხშირად დამოუკიდებლობისა და მოქნილობის სინონიმი, გვთავაზობს უნიკალურ კარიერულ გზას, რომელიც ხელახლა განსაზღვრავს ინდივიდების მუშაობისა და მათი პროფესიების ჩართვას...',
    },
    // {
    //   imageUrl: Blog2,
    //   header: 'თავი 2',
    //   text: 'იქნება მალე...',
    // },
    
  ];

  return (
    <div className='mt-28  border border-gray-700 border-solid m-10 rounded-3xl'>
      {blogsData.map((blog, index) => (
        <EachBlog
          key={index}
          imageUrl={blog.imageUrl}
          header={blog.header}
          text={blog.text}
        />
      ))}
    </div>
  );
};


const Blogs = () => {
    return <Layout children={<Blogss />} />;
  };

export default Blogs
import React from 'react';
import { useForm, ValidationError } from '@formspree/react';
import Privacy from '../auth/Privacy';
import Logo from "../../../styles/assets/Logo.png"
const CompanyQ = () => {
  const [state, handleSubmit] = useForm("myyqvgyk");
  if (state.succeeded) {
      return <p>მადლობა ინფორმაციისთვის, შეგეხმიანებით!</p>;
  }

  return (
    <div className="max-w-2xl w-4/5 mx-auto animate__animated animate__zoomIn font-FiragoB">
        <div className='flex justify-center'>
        <img src={Logo} alt='Logo' className='Logo w-36 h-36' />
      </div>
      <form onSubmit={handleSubmit}  className="p-6 bg-transparent">


        <div className=' mb-5 '>
          <div className="mb-5">
           <input
              type="text"
              id="კომპანიის სახელი"
              className="w-full px-3 py-2 border rounded-2xl focus:outline-none focus:border-white"
              name="კომპანიის სახელი"
             placeholder="კომპანიის სახელი"
            />
            <ValidationError 
            prefix="კომპანიის სახელი" 
            field="კომპანიის სახელი"
            errors={state.errors}
      />
          </div>
          <div className="">
            <input
              type="text"
              id="საიდენტიფიკაციო კოდი"
              className="w-full px-3 py-2 border rounded-2xl focus:outline-none focus:border-white"
              name="საიდენტიფიკაციო კოდი"
              placeholder="საიდენტიფიკაციო კოდი"
            />
            <ValidationError 
            prefix="საიდენტიფიკაციო კოდი" 
            field="საიდენტიფიკაციო კოდი"
            errors={state.errors}
      />
          </div>
        </div>
      
        <div className="mb-4">
          <input
            type="email"
            id="email"
            className="w-full px-3 py-2 border rounded-2xl focus:outline-none focus:border-indigo-500"
            name="email"
            placeholder="მეილი"
          />
          <ValidationError 
            prefix="Email" 
            field="email"
            errors={state.errors}
      />
        </div>

        

        <div className="mb-4">
          <textarea
            id="კომენტარი"
            className="w-full px-3 py-2 border rounded-2xl focus:outline-none focus:border-indigo-500"
            name="კომენტარი"

            placeholder="დაგვიტოვეთ დამატებითი კომენტარი"
          />
           <ValidationError 
        prefix="კომენტარი" 
        field="კომენტარი"
        errors={state.errors}
      />
        </div>
        <Privacy />
        <button className='p-2 w-40 rounded-xl font-bold text-white bg-[#3749EA]' type="submit" disabled={state.submitting}>
        გაგზავნა
      </button>
      </form>
    </div>
  );
};

export default CompanyQ; 

import React from 'react';
import SubProjects from './SubProjects';
import Eins from '../../../styles/assets/eins.jpg'
import Zwei from '../../../styles/assets/zwei.jpg'
import Drei from '../../../styles/assets/drei.jpg'
import Vier from '../../../styles/assets/vier.jpg'
import Funf from '../../../styles/assets/funf.jpg'
import Seghs from '../../../styles/assets/seghs.jpg'
import Sieben from '../../../styles/assets/sieben.jpg'
import Acht from '../../../styles/assets/acht.jpg'
import Neun from '../../../styles/assets/Neun.jpeg'

const Projects = () => {
    const projectData = [
        { title: 'საბა ციკოლია', description:"3D დიზაინერი",  imgSrc: Eins},
        { title: 'გოგა ოსეფაშვილი', description:"3D ანიმატორი",  imgSrc: Zwei },
        { title: 'ალექსანდრე ფოჩხიძე', description:"დიზაინერი",  imgSrc: Drei },
        { title: 'ირაკლი წერეთელი',  description:"3D დიზაინერი", imgSrc: Vier },
        { title: 'მაია კაცაძე', description:"ხელნაკეთი თოჯინები",  imgSrc: Funf },
        { title: 'ალექსანდრე ლობჟანიძე', description:"3D ბეჭდვა",  imgSrc: Seghs },
        { title: 'შოთა თხილიშვილი', description:"მენიღბე",  imgSrc: Sieben },
        { title: 'ელენე გორგაძე', description:"გრაფიკული დიზაინერი",  imgSrc: Acht },
        { title: 'ნინო დარჩიაშვილი', description:"ხელნაკეთი აქსესუარები",  imgSrc: Neun },
      ];
      

  return (
    <div>
      <div className='Block text-black text-center text-5xl'>გახდი ჰამქარი</div>
      <div className="">
        <SubProjects projects={projectData} />
      </div>
    </div>
  );
};

export default Projects;
import React, { Fragment, createContext, useReducer } from "react";
import Layout from "../layout";
import Slider from "./Slider";
import ProductCategory from "./ProductCategory";
import { homeState, homeReducer } from "./HomeContext";
import SingleProduct from "./SingleProduct";
// import Packege from "./Packege";
import Projects from "./Projects";
import Orange from "./Orange";
import Advertisement from "./Advertisement";
export const HomeContext = createContext();

const HomeComponent = () => {
  return (
    <Fragment>
      <Slider />
      {/* Category, Search & Filter Section */}
      <section className="m-4 md:mx-8 md:my-6">
        <ProductCategory />
      </section>
      {/* Product Section */}
      <section>
        <SingleProduct />
      </section>
      <section className="mt-36 mb-20">
      <Projects />
    </section>
    {/* <Packege /> */}
      <section className="orange mt-16 w-full h-[790px]">
        <Orange />
      </section>
     <Advertisement />
    </Fragment>
   
  );
};

const Home = (props) => {
  const [data, dispatch] = useReducer(homeReducer, homeState);
  return (
    <Fragment>
      <HomeContext.Provider value={{ data, dispatch }}>
        <Layout children={<HomeComponent />} />
      </HomeContext.Provider>
    </Fragment>
  );
};

export default Home;

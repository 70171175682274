import React from 'react';

const SubProjects = ({ projects }) => {
  return (
    <div className='grid grid-cols-3 gap-10 p-20 mt-24 cd:grid-cols-1'>
      {projects.map((project, index) => (
        <div
          key={index}
          className='bg-cover bg-center p-4 h-72 ok:bg-top ok:h-96'
          style={{ backgroundImage: `url(${project.imgSrc})` }}
        >
          <h3 className='text-white text-2xl shadowa'>{project.title}</h3>
          <p className='text-white text-xl shadova'>{project.description}</p>
        </div>
      ))}
    </div>
  );
};

export default SubProjects;
// 27 top
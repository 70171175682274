import React from 'react'
import { useHistory, useLocation } from "react-router-dom";
import { isAuthenticate } from "../auth/fetchApi";


const Orange = () => {
    const history = useHistory();

  return (
    <div className='text-center pt-44 ab:pt-16 cd:pt-20 '>
        <div className=''>
            <h1 className='text-black font-normal text-7xl ab:text-4xl'>დაასაქმე ან თავად</h1>
            <h1 className='text-black font-normal text-7xl ab:text-4xl'> გახდი ჰამქარი</h1>
            <p className='text-black text-base mt-3'> დროა შენი პროფესია, ნიჭი თუ ჰობი აქციო შემოსავლის წყაროდ!
                <span className='block'>შესთავაზე ყველას, ის რაც კარგად გამოგდის და გაზარდე</span>
                <span className='block'>შენი შემოსავალი</span>
            </p>
        </div>
        <div className=" mt-20 mb-14 grid justify-center">
        {isAuthenticate() ? (
        <button
       className="text-2xl bg-shavi text-white px-7 py-1  rounded-[70px]"
       type="">
        მოგესალმებით!
       </button>
      ) : (
        <div className="text-2xl bg-shavi text-white px-7 py-1  rounded-[70px] ">
          დარეგისტრირდი
        </div>
      )}
               </div>
        <div className='text-center'>
            <p className='text-2xl'> დაინტერესდი? <span onClick={(e) => history.push("/Faq")} className='underline cursor-pointer'>გაიგეთ მეტი...</span></p>
        </div>
    </div>
  )
}

export default Orange
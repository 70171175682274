import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

export const getAllProduct = async () => {
  try {
    let res = await axios.get(`${apiURL}/api/product/all-product`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const createProduct = async ({
  pName,
  pDescription,
  pCreatorDescription,
  pCreatorName,
  pImages,
  pStatus,
  pCategory,
  pQuantity,
  pPrice,
  pOffer,
  pDate,
  pTele
}) => {
  /* Most important part for uploading multiple image  */
  let formData = new FormData();
  formData.append("pImages", pImages);
  /* Most important part for uploading multiple image  */
  formData.append("pName", pName);
  formData.append("pCreatorDescription", pCreatorDescription);
  formData.append("pCreatorName", pCreatorName);
  formData.append("pTele", pTele);
  formData.append("pDescription", pDescription);
  formData.append("pStatus", pStatus);
  formData.append("pCategory", pCategory);
  formData.append("pQuantity", pQuantity);
  formData.append("pPrice", pPrice);
  formData.append("pOffer", pOffer);
  formData.append("pDate", pDate);

  try {
    let res = await axios.post(`${apiURL}/api/product/add-product`, formData);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const editProduct = async (product) => {
  /* Most important part for updating multiple image  */
  let formData = new FormData();

  /* Most important part for updating multiple image  */
  formData.append("pId", product.pId);
  formData.append("pName", product.pName);
  formData.append("pDescription", product.pDescription);
  formData.append("pCreatorDescription", product.pCreatorDescription);
  formData.append("pTele", product.pTele);
  formData.append("pCreatorName", product.pCreatorName);
  formData.append("pImages", product.pImages);
  formData.append("pStatus", product.pStatus);
  formData.append("pCategory", product.pCategory);
  formData.append("pQuantity", product.pQuantity);
  formData.append("pPrice", product.pPrice);
  formData.append("pOffer", product.pOffer);
  formData.append("pDate", product.pDate);

  try {
    let res = await axios.post(`${apiURL}/api/product/edit-product`, formData);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteProduct = async (pId) => {
  try {
    let res = await axios.post(`${apiURL}/api/product/delete-product`, { pId });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const productByCategory = async (catId) => {
  try {
    let res = await axios.post(`${apiURL}/api/product/product-by-category`, {
      catId,
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const productByPrice = async (price) => {
  try {
    let res = await axios.post(`${apiURL}/api/product/product-by-price`, {
      price,
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  Fragment,
} from "react";
import { useHistory } from "react-router-dom";
import { getAllProduct } from "../../admin/products/FetchApi";
import { HomeContext } from "./index";
import { isWishReq, unWishReq, isWish } from "./Mixins";
import "./style.css";
import Star from "../../../styles/assets/star.svg";

import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const SingleProduct = (props) => {
  const { data, dispatch } = useContext(HomeContext);
  const [newProducts, setnewProducts] = useState([]);
  const [useProducts, setuseProducts] = useState([]);

  const [filtery, setFilter] = useState(''); 

  const handleFilter = (profession) => {
    console.log(newProducts)
    setFilter(profession);
    if(filtery === ""){
      const nProducts = newProducts
    setuseProducts(nProducts);
    }else{
    const nProducts = newProducts
    .filter(p => p.pCategory.cName === filtery)
    setuseProducts(nProducts);
  }
}

  const fetchData = useCallback(async () => {
    dispatch({ type: "loading", payload: true });
    try {
      let responseData = await getAllProduct();
      setTimeout(() => {
        if (responseData && responseData.Products) {
          let fproducts = responseData.Products.filter(
            (produc) => produc.pStatus === "Active"
          );
          const newProductes = fproducts.sort((a, b) => {
            return new Date(b.pDate) - new Date(a.pDate);
          });
          setuseProducts(newProductes);
          setnewProducts(newProductes)
         

          dispatch({ type: "setProducts", payload: fproducts });
          dispatch({ type: "loading", payload: false });
        }
      }, 300);
    } catch (error) {
      console.log(error);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (data.loading) {
    return (
      <div className="col-span-2 md:col-span-3 lg:col-span-4 flex items-center justify-center py-24">
        <svg
          className="w-12 h-12 animate-spin text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          ></path>
        </svg>
      </div>
    );
  }

  
  return (
    <Fragment>
      <div>
        <div className="m-8 md:mx-20 md:my-4 flex flex-wrap  gap-10">
      <ProductList products={useProducts} />
      </div>
      </div>
    </Fragment>
  );
};

const ProductList = ({ products }) => {
  const [wList, setWlist] = useState(
    JSON.parse(localStorage.getItem("wishList"))
  );
  const history = useHistory();

  return (
    <Fragment>
     <Swiper
              // install Swiper modules
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
              breakpoints={{
                // when window width is >= 640px
                280: {
                  width: 280,
                  slidesPerView: 1.4,
                  spaceBetween: 130,
                },
                400: {
                  width: 400,
                  slidesPerView: 2,
                  spaceBetween: 140,
                },
                640: {
                  width: 640,
                  slidesPerView: 3,
                  spaceBetween: 250,
                },
                880: {
                  width: 880,
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
                1080: {
                  width: 1080,
                  slidesPerView: 4,
                  spaceBetween: 150,
                },
                1280: {
                  width: 1280,
                  slidesPerView: 4,
                  spaceBetween: 30, // Adjusted for better spacing
                },
                1490: {
                  width: 1490,
                  slidesPerView: 5,
                  spaceBetween: 30,
                },
                1600: {
                  width: 1600,
                  slidesPerView: 6,
                  spaceBetween: 30,
                },
                1800: {
                  width: 1800,
                  slidesPerView: 7,
                  spaceBetween: 30,
                },
                2100: {
                  width: 2100,
                  slidesPerView: 8,
                  spaceBetween: 30,
                },
              }}
              centeredSlides={products.length <= 8}
              loop
              autoplay={{
                delay: 2100,
                disableOnInteraction: false,
              }}
              scrollbar={{ draggable: true }}
            >
      {products && products.length > 0 ? (
        products.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="grid justify-items-center mb-6 relative col-span-1 border-solid border rounded-3xl border-black-rgba w-64 h-[460px]">
                <div className="p-12 ">
                  <img
                    onClick={(e) => history.push(`/products/${item._id}`)}
                    className="w-32 h-32 object-cover object-center cursor-pointer rounded-full  "
                    src={item.pImages[0]}
                    alt="profile"
                  />
                  
                </div>
                {/* WhisList Logic End */}
                <div className="flex items-center ">
                  <div
                    className="text-shavi  text-base leading-tight break-words cursor-pointer "
                    onClick={(e) => history.push(`/products/${item._id}`)}
                  >
                    <p className="text-xl text-center font-semibold">{item.pName}</p>
                    <p className="text-base text-center text-lips">{item.pCreatorName}</p>
                    <div className="flex justify-center"> 
                    <img className="mr-1" alt="star" src={Star} />
                    <img className="mr-1" alt="star" src={Star} />
                    <img className="mr-1" alt="star" src={Star} />
                    <img className="mr-1" alt="star" src={Star} />
                    <img className="mr-1" alt="star" src={Star} />
                    </div>
                   
                  </div>
                </div>

                {item.pOffer !== 0 && item.pOffer !== "0" ? (
                  
                <div className="grid">
                  <button 
                  onClick={(e) => history.push(`/products/${item._id}`)}
                  className="bg-[#0085FF] text-white text-[23px] text-center px-10 mb-10  rounded-3xl">
                    მეტი
                  </button>

                  {/* WhisList Logic  */}
                  {/* <div className=" mx-2 my-2 md:mx-4">
                    <svg
                      onClick={(e) => isWishReq(e, item._id, setWlist)}
                      className={`${
                        isWish(item._id, wList) && "hidden"
                      } w-5 h-5 md:w-6 md:h-6 cursor-pointer text-yellow-700 transition-all duration-300 ease-in`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                      />
                    </svg>
                    <svg
                      onClick={(e) => unWishReq(e, item._id, setWlist)}
                      className={`${
                        !isWish(item._id, wList) && "hidden"
                      } w-6 h-6 md:w-7 md:h-7 cursor-pointer text-yellow-700 transition-all duration-300 ease-in`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div> */}
                  </div>
                ) : (
                  <div className="flex items-center justify-between">
                  <p className="  text-base ml-7 bolda text-just bg-sky center  rounded-3xl text-center px-3">
                    {" "}
                    Buy ₾{item.pPrice} GEL
                  </p>

                  {/* WhisList Logic  */}
                  <div className=" mx-2 my-2 md:mx-4">
                    <svg
                      onClick={(e) => isWishReq(e, item._id, setWlist)}
                      className={`${
                        isWish(item._id, wList) && "hidden"
                      } w-5 h-5 md:w-6 md:h-6 cursor-pointer text-yellow-700 transition-all duration-300 ease-in`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                      />
                    </svg>
                    <svg
                      onClick={(e) => unWishReq(e, item._id, setWlist)}
                      className={`${
                        !isWish(item._id, wList) && "hidden"
                      } w-6 h-6 md:w-7 md:h-7 cursor-pointer text-yellow-700 transition-all duration-300 ease-in`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  </div>
                )}
              </div>
            
            </SwiperSlide>
          );
        })
      ) : (
        <div className="col-span-2 md:col-span-3 lg:col-span-4 flex items-center justify-center py-24 text-2xl">
          ჰამქარი ვერ მოიძებნა 
        </div>
      )}
      </Swiper>
    </Fragment>
  );
};

export default SingleProduct;